import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import PostLink from "../components/post-link";
import HeroHeader from "../components/heroHeader";

import Logo from "../../static/assets/orange.svg";
import Bg from "../../static/assets/hex.svg";

import Tilt from "react-tilt";

const IndexPage = ({
  data: {
    site,
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter((edge) => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map((edge) => (
      <Tilt options={{ max: 25, speed: 500 }}>
        <PostLink key={edge.node.id} post={edge.node} />
      </Tilt>
    ));

  return (
    <Layout>
      <Helmet>
        <title>{site.siteMetadata.title}</title>
        <meta name="description" content={site.siteMetadata.description} />
      </Helmet>
      <div class="logo">
        <Logo />
      </div>
      <div class="background-hex">
        <Bg />
      </div>
      <HeroHeader />
      <h2>Blog Posts &darr;</h2>
      <div className="grids">{Posts}</div>
    </Layout>
  );
};

export default IndexPage;
export const pageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        fileAbsolutePath: {}
        frontmatter: { path: { regex: "^/blogs/" } }
      }
    ) {
      edges {
        node {
          id
          excerptAst(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            thumbnail
            path
          }
        }
      }
    }
  }
`;
