import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Navigation from "../components/navigation";
import "prismjs/themes/prism-okaidia.css";

import LinkedIn from "../../static/assets/linkedin.svg";
import Medium from "../../static/assets/medium.svg";
import Github from "../../static/assets/github.svg";
import Mail from "../../static/assets/mail.svg";

import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";
deckDeckGoHighlightElement();

const Layout = ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );
  return (
    <div className="site-wrapper">
      <header className="site-header">
        <div className="site-title">
          <Link to="/">{data.site.siteMetadata.title}</Link>
        </div>
        <Navigation />
      </header>
      {children}
      <footer className="site-footer">
        <div>
          <p>
            &copy; 2019-{new Date().getFullYear()} &bull; Copied with{" "}
            <span role="img" aria-label="love">
              ❤️
            </span>{" "}
            by Aayush Malhotra
          </p>
          <div>
            <a
              href="https://www.linkedin.com/in/aayush-malhotra-390a9414a/"
              className="social"
              aria-label="Link to Aayush Malhotra's Linkedin"
            >
              <LinkedIn />
            </a>
            <a
              href="https://medium.com/@aayushm864"
              className="social"
              aria-label="Link to Aayush Malhotra's Medium page"
            >
              <Medium />
            </a>
            <a
              href="https://github.com/AadumKhor"
              className="social"
              aria-label="Link to Aayush Malhotra's Github profile"
            >
              <Github />
            </a>
            <a
              href="mailto:aayushm864@gmail.com"
              className="social"
              aria-label="Mail Aayush Malhotra"
            >
              <Mail />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
