import React from "react";
import { Link } from "gatsby";

const AboutPostLink = ({ post }) => (
  <article className="about-card ">
    <header>
      <h2 className="post-title">
        <Link to={post.frontmatter.path} className="post-link">
          {post.frontmatter.title}
        </Link>
      </h2>
    </header>
  </article>
);
export default AboutPostLink;
