import React from "react";
import { StaticQuery, graphql } from "gatsby";
import FadeIn from "react-fade-in/lib/FadeIn";

const NAMES = {
  AAYUSH: "Aayush Malhotra",
  AADUMKHOR: "Aadumkhor",
};

const HeroHeader = () => {
  const name = NAMES.AAYUSH;

  // useEffect(() => {
  //   setInterval(() => {
  //     setName(NAMES.AADUMKHOR)
  //     setTimeout(() => {
  //       setName(NAMES.AAYUSH)
  //     }, 200)
  //   }, 2200)
  // }, []);

  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          site {
            siteMetadata {
              home {
                title
                description
              }
            }
          }
        }
      `}
      render={(data) => (
        <div className="hero-header">
          <FadeIn delay={500} transitionDuration={1500}>
            <h1 class="headline-mini">Hi! I'm</h1>
            <h1 class="headline-main">{name}</h1>
            <div
              className="primary-content"
              dangerouslySetInnerHTML={{
                __html: data.site.siteMetadata.home.description,
              }}
            />
          </FadeIn>
        </div>
      )}
    />
  );
};

export default HeroHeader;
