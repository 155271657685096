import React from "react";
import { ThemeToggler } from "gatsby-plugin-dark-mode";

const ThemeChanger = () => (
  <ThemeToggler>
    {({ theme, toggleTheme }) => (
      <label>
        <input
          type="checkbox"
          className="theme-changer"
          onChange={(e) => {
            toggleTheme(e.target.checked ? "dark" : "light");
            // toggleTheme("dark")
            // if (e.target.checked) {
            //   var header = document.getElementsByClassName("hero-header");
            //   console.log(header.style.backgroundImage);
            // }
          }}
          checked={theme === "dark"}
        />{" "}
        <div className="mode-container">
          <i className="gg-sun"></i>
          <i className="gg-moon"></i>
        </div>
      </label>
    )}
  </ThemeToggler>
);

export default ThemeChanger;
