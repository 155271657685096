import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";

import FadeIn from "react-fade-in/lib/FadeIn";
import Tilt from "react-tilt";
import AboutPostLink from "../components/about-post-link";

const AboutPage = ({
  data: {
    site,
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter((edge) => !!edge.node.frontmatter.date)
    .map((edge) => (
      <Tilt options={{ max: 25, speed: 500 }}>
        <AboutPostLink key={edge.node.id} post={edge.node} />
      </Tilt>
    ));

  return (
    <Layout>
      <Helmet>
        <title>Contact — {site.siteMetadata.title}</title>
        <meta
          name="description"
          content={"About page of " + site.siteMetadata.description}
        />
      </Helmet>
      <FadeIn transitionDuration={1000} className="about_fade">
        <div className="two-grids -contact">
          <div
            className="post-thumbnail"
            style={{
              backgroundImage: `url('/assets/about_image.jpeg')`,
              height: `80vh`,
              marginBottom: 0,
            }}
          ></div>
          <div class="about_bg">
            <h1 class="who_am_i_heading">Who Am I?</h1>
            <p>{site.siteMetadata.about_first}</p>
            <p>{site.siteMetadata.about_second}</p>
            <p>
              PS : Huge shoutout to{" "}
              <a href="https://tanishq0143.github.io/Portfolio/">
                Tanishq Chandolia
              </a>{" "}
              and <a href="https://github.com/dhruvmisra">Dhruv Misra</a> for
              helping me build this. Special thanks to{" "}
              <a href="https://silentlad.com/">Divyansh Tripathi</a> for setting
              an example.
            </p>
          </div>
        </div>
      </FadeIn>
      <h2>Past Projects</h2>
      <div className="grids">{Posts}</div>
    </Layout>
  );
};
export default AboutPage;
export const pageQuery = graphql`
  query AboutPageQuery {
    site {
      siteMetadata {
        title
        description
        about_first
        about_second
      }
    }
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        fileAbsolutePath: {}
        frontmatter: { path: { regex: "^/projects/" } }
      }
    ) {
      edges {
        node {
          id
          excerptAst(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            thumbnail
            path
          }
        }
      }
    }
  }
`;
