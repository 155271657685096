import React from "react";
import { Link } from "gatsby";
import ThemeChanger from "../components/themeChanger";

import resume from "../../static/assets/resume.pdf";

const Navigation = (props) => (
  <nav className="navigation">
    <Link to="/about">About</Link>
    <a href={resume} download>
      Resume
    </a>
    <ThemeChanger />
  </nav>
);

export default Navigation;
